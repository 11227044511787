const CAPTCHA_SITE_KEY = '6LeG-BIqAAAAACwkFFgA5e7F5pYPTR-sAS8qlR2G';

const CAPTCHA_ACTION_TYPE_LOGIN = 'login';
const CAPTCHA_ACTION_TYPE_LOGIN_PASS_RESET = 'loginPassReset';
const CAPTCHA_ACTION_TYPE_REGISTRATION = 'registration';

export default {
  data() {
    return {
      captchaData: {
        sitekey: CAPTCHA_SITE_KEY,
        token: '',
        actionMap: {
          1: CAPTCHA_ACTION_TYPE_LOGIN,
          2: CAPTCHA_ACTION_TYPE_LOGIN_PASS_RESET,
          3: CAPTCHA_ACTION_TYPE_REGISTRATION,
        },
        action: '',
        isReady: false,
      },
    };
  },
  computed: {
    captchaTypes() {
      return Object.values(this.captchaData.actionMap);
    },
  },
  mounted() {
    // Check if reCAPTCHA is loaded at component mount
    this.ensureCaptchaLoaded();
    
    // Listen for the recaptcha-loaded event
    window.addEventListener('recaptcha-loaded', () => {
      this.captchaData.isReady = true;
    });
  },
  beforeDestroy() {
    // Clean up event listener
    window.removeEventListener('recaptcha-loaded', () => {
      this.captchaData.isReady = true;
    });
  },
  methods: {
    ensureCaptchaLoaded() {
      // Check global flag first (most reliable)
      if (window.reCAPTCHALoaded) {
        this.captchaData.isReady = true;
        return true;
      }
      
      // Check if grecaptcha is loaded globally
      if (window.grecaptcha && window.grecaptcha.ready) {
        this.captchaData.isReady = true;
        window.reCAPTCHALoaded = true;
        return true;
      }
      
      // If not loaded yet, set up a listener for when it's ready
      if (typeof window.vueRecaptchaApiLoaded === 'function') {
        const originalCallback = window.vueRecaptchaApiLoaded;
        window.vueRecaptchaApiLoaded = () => {
          originalCallback();
          this.captchaData.isReady = true;
        };
      }
      
      return false;
    },
    setCaptchaAction(action) {
      if (this.captchaTypes.indexOf(this.captchaData.actionMap[action]) === -1) {
        throw new Error(`Not defined captcha action type '${action}'`);
      }

      this.$set(this.captchaData, 'action', this.captchaData.actionMap[action]);
      this.processCaptcha();
    },
    doPostCaptchaAction(recaptchaToken) {
      switch (this.captchaData.action) {
        case CAPTCHA_ACTION_TYPE_LOGIN:
          this.login(recaptchaToken);
          break;

        case CAPTCHA_ACTION_TYPE_LOGIN_PASS_RESET:
          this.resetPass(recaptchaToken);
          break;

        case CAPTCHA_ACTION_TYPE_REGISTRATION:
          this.registration(recaptchaToken);
          break;
      }
    },
    captchaErrorNotify() {
      this.$message.errorMessage('Ошибка обновления капчи');
    },
    errorCaptcha(error) {
      this.captchaErrorNotify();
      throw new Error(`Captcha error: ${error}`);
    },
    processCaptcha() {
      try {
        // First ensure captcha is loaded
        if (!this.captchaData.isReady) {
          const isLoaded = this.ensureCaptchaLoaded();
          if (!isLoaded) {
            console.warn('reCAPTCHA not fully loaded yet, retrying in 500ms');
            setTimeout(() => this.processCaptcha(), 500);
            return;
          }
        }
        
        this.$refs.recaptcha.execute();
      } catch (e) {
        console.error(e, 'processCaptcha');
        this.captchaErrorNotify();
      }
    },
    onCaptchaExpired() {
      try {
        this.resetCaptcha();
      } catch (e) {
        console.error(e, 'onCaptchaExpired');
        this.captchaErrorNotify();
      }
    },
    resetCaptcha() {
      try {
        this.$refs.recaptcha.reset();
      } catch (e) {
        console.error(e, 'resetCaptcha');
        this.captchaErrorNotify();
      }
    },
  },
};
